.rocket {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  height: auto;
  animation: rocketFloat 8s ease-in-out infinite;
  z-index: 0;
  transition: all 0.5s ease-out;
  opacity: 0.8;
}

.rocket.launch {
  bottom: 75%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  opacity: 0;
  transition: all 2s cubic-bezier(0.47, 0, 0.745, 0.715);
}

@keyframes rocketFloat {
  0% {
    transform: translate(-50%, 0) rotate(2deg);
  }
  25% {
    transform: translate(-45%, 0) rotate(-1deg);
  }
  50% {
    transform: translate(-50%, 0) rotate(3deg);
  }
  75% {
    transform: translate(-55%, 0) rotate(-2deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(2deg);
  }
} 

.mars {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: auto;
  animation: marsFloat 12s ease-in-out infinite;
  z-index: 0;
  opacity: 0.5;
}

@keyframes marsFloat {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-48%, -48%) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-52%, -52%) rotate(-1deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
} 